// Attention: these are linked to translations
export var CardType;
(function (CardType) {
    CardType["Scenario"] = "scenario";
    CardType["PipelineStep"] = "pipeline-step";
    CardType["GameRule"] = "game-rule";
    CardType["Review"] = "review";
})(CardType || (CardType = {}));
// Attention: these are linked to translations
export var CardTag;
(function (CardTag) {
    CardTag["DeliveryStep"] = "delivery-step";
    CardTag["ManualStep"] = "manual-step";
    CardTag["DeployStep"] = "deploy-step";
    CardTag["SystemTest"] = "system-test";
    CardTag["Package"] = "package";
})(CardTag || (CardTag = {}));
